<template>
    <v-app>
        <v-main>
            <v-container>
                <v-row align="center" justify="center">
                <v-col cols="12">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    
                    
                    </div>
                    
                    <div class="contant_box_404 text-center">
                        <h1>
                        ທ່ານຫຼົງທາງ / Page Not Found
                        </h1>
                        <br><br>
                        <p>ບໍ່ມີໜ້າເວບທີ່ທ່ານຕ້ອງການເຂົ້າຫາ!!!!!</p>
                        
                        <router-link to="/" class="link_404">ກັບຄືນໜ້າຫຼັກ</router-link>
                    </div>
                </v-col>
            </v-row>
            </v-container>
            
        </v-main>
    </v-app>
    
</template>
<script>
export default {
    name:'NotFound'
}
</script>
<style scoped>
.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{

    background-image: url('@/assets/dribbble_1.gif');
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1{
    font-size:80px;
}

.four_zero_four_bg h3{
    font-size:80px;
}

.link_404{
    color: #fff!important;
    text-decoration: none;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
.contant_box_404{ margin-top:-50px;}
</style>