<template>
  <div>
    <LoaderComponent/>
    <router-view></router-view>
  </div>
</template>

<script>

import LoaderComponent from "@/components/Loader";
export default {
  name: 'App',
  components: {LoaderComponent},
  data: () => ({
    //
  }),
};
</script>
