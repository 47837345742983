<template>
  <branch-chart :styles="ChartStyle"/>



</template>

<script>

  import BranchChart from "@/components/BranchChart";

  export default {
    name: 'HomeView',

    components: {
      BranchChart,
    },
    data:()=>({
      ChartStyle:{
        height: `${600}px`,
      }
    }),
    mounted() {
      // this.login()
    },
    methods:{
     async getChartData(){
       try {
         let res=await window.axios.post('login',{username:'psvb',password:'@Psvb2022!#'})
         console.log(res.data)
       }catch (e) {
         console.log(e)
       }
      },

    }
  }
</script>
