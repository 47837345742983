<template>
    <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
    />
</template>

<script>
    import { Bar } from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
    ChartJS.defaults.font.family = "Noto Sans Lao"

    export default {
        name: 'BranchChart',
        components: { Bar },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 800
            },
            height: {
                type: Number,
                default: 800
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type:Object,
                default:()=>{}
            }
        },
        data() {
            return {
                chartData: {
                    labels: [ 'Bankca', 'Retail', 'Coporate','Non-sale' ],
                    datasets: [ {
                        label:'ຕົວແທນທັງໝົດ',
                        backgroundColor: '#FFE0B2',
                        data: [0, 0, 0,0]
                    } ]
                },
                chartOptions: {

                    responsive: true,
                    maintainAspectRatio: false,

                }
            }
        },
        mounted() {
            this.getChartData()
        },
        methods:{
            async getChartData(){
                try {
                    let r=await window.axios.get('/dash')
                    this.chartData.labels=r.data.title
                    this.chartData.datasets[0].data=r.data.amount
                }catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>