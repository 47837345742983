<template>
    <v-app>
        <v-main>
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="10" md="8" lg="8" xl="6">
                        <v-card class="elevation-6 mt-15">
                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card-text class="mt-12">
                                                <h3
                                                        class="text-center"
                                                > {{$t("login.welcome_to_ams")}}</h3>
                                                <!-- <h5
                                                class="text-center  grey--text "
                                                >ກະລຸນາເຂົ້າສູ່ລະບົບເພື່ອຈັດການ, ແກ້ໄຂບັນດາຕົວແທນຕ່າງໆ</h5> -->
                                                <v-form ref="login_form" @submit.prevent="Login">
                                                    <v-row align="center" justify="center">

                                                        <v-col cols="12" sm="10">
                                                            <v-alert
                                                                    v-if="error"
                                                                    dense
                                                                    outlined
                                                                    type="error"
                                                                    class="mt-5"
                                                            >
                                                                ຊື່ຜູ້ໃຊ້ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ
                                                            </v-alert>
                                                            <v-text-field
                                                                    :label="$t('login.username')"
                                                                    outlined
                                                                    dense
                                                                    :rules="rules.username"
                                                                    color="green darken-4"
                                                                    autocomplete="false"
                                                                    v-model="credential.username"
                                                                    class="mt-10"
                                                            />
                                                            <v-text-field
                                                                    :label="$t('login.password')"
                                                                    outlined
                                                                    dense
                                                                    :rules="rules.password"
                                                                    color="green darken-4"
                                                                    autocomplete="false"
                                                                    type="password"
                                                                    v-model="credential.password"

                                                            />

                                                            <v-btn type="submit" color="green darken-4" dark block
                                                                   tile>{{$t("login.login")}}
                                                            </v-btn>


                                                        </v-col>

                                                    </v-row>
                                                </v-form>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" md="6" class="green darken-4 rounded-bl-xl">
                                            <div style="  text-align: center; padding: 180px 0;">
                                                <v-card-text class="white--text">
                                                    <h2 class="text-center ">ບໍລິສັດ ພົງສະຫວັນປະກັນໄພ (ເອພີເອ)
                                                        ຈຳກັດ</h2>
                                                    <h6
                                                            class="text-center"
                                                    >PHONGSAVANH INSURANCE (APA) CO.,LTD<br> You're not just another
                                                        customer...<br>We're not just another Insurer...</h6>
                                                </v-card-text>
                                                <!-- <div class="text-center">
                                                    <v-btn tile outlined dark @click="step++" >ລົງທະບຽນ</v-btn>
                                                </div> -->
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-row>
                                        <v-col cols="12" md="6" class="green darken-4 rounded-br-xl">
                                            <div style="  text-align: center; padding: 180px 0;">
                                                <v-card-text class="white--text">
                                                    <h3 class="text-center ">Alredy Signed up?</h3>
                                                    <h6
                                                            class="text-center"
                                                    >Log in to your account so you can continue building and<br> editing
                                                        your onboarding flows</h6>
                                                </v-card-text>
                                                <div class="text-center">
                                                    <v-btn tile outlined dark @click="step--">Log in</v-btn>
                                                </div>
                                            </div>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-card-text class="mt-12">
                                                <h4
                                                        class="text-center"
                                                >Sign Up for an Account</h4>
                                                <h6
                                                        class="text-center  grey--text "
                                                >Let's get you all set up so you can start creatin your <br>
                                                    first onboarding experiance</h6>
                                                <v-row align="center" justify="center">
                                                    <v-col cols="12" sm="8">
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                        label="First Name"
                                                                        outlined
                                                                        dense
                                                                        color="green darken-4"
                                                                        autocomplete="false"
                                                                        class="mt-4"
                                                                />
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                        label="Last Name"
                                                                        outlined
                                                                        dense
                                                                        color="green darken-4"
                                                                        autocomplete="false"
                                                                        class="mt-4"
                                                                />
                                                            </v-col>
                                                        </v-row>
                                                        <v-text-field
                                                                label="Email"
                                                                outlined
                                                                dense
                                                                color="green darken-4"
                                                                autocomplete="false"
                                                        />
                                                        <v-text-field
                                                                label="Password"
                                                                outlined
                                                                dense
                                                                color="green darken-4"
                                                                autocomplete="false"
                                                                type="password"

                                                        />
                                                        <v-row>
                                                            <v-col cols="12" sm="7">
                                                                <v-checkbox

                                                                        label="I Accept AAE"
                                                                        class="mt-n1"
                                                                        color="green darken-4"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="12" sm="5">
                                                                <span class="caption green--text ml-n4 text--darken-4">Terms &Conditions</span>
                                                            </v-col>
                                                        </v-row>
                                                        <v-btn color="green darken-4" dark block tile>Sign up</v-btn>


                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>

</template>

<script>
import i18n from '@/i18n'
    export default {
        name: 'LoginView',
        data: () => ({
            step: 1,
            credential: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    v => !!v || i18n.t('login.validate_username')
                ],
                password: [
                    v => !!v || i18n.t('login.validate_password')
                ]
            },
            error: false
        }),

        methods: {
            async Login() {
                if (!this.$refs.login_form.validate()) {
                    return
                }
                try {
                    let res = await window.axios.post('login', this.credential)
                    await this.$store.dispatch('login', res.data.token)

                    this.$router.push({path: '/'})
                } catch (e) {
                    this.error = true
                    setTimeout(() => {
                        this.error = false
                    }, 3000)
                    console.log(e)
                }
            }
        }
    }
</script>

<style scoped>
    .v-application .rounded-bl-xl {
        border-bottom-left-radius: 300px !important;
    }

    .v-application .rounded-br-xl {
        border-bottom-right-radius: 300px !important;
    }
</style>