
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import MainLayout from '@/views/MainLayout'
import NotFound from '@/views/NotFound.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    component: MainLayout,
    meta: {
      requiresAuth: true
    },
    children:[
      {
          path:'/',
          name:'home',
          component:HomeView,

      },
        //region Branch
      {
        path: '/branch',
        name: 'branch',
        component: () => import('@/views/branch/BranchList.vue'),

      },
      {
        path: '/branch/create',
        component: () => import('@/views/branch/CreateBranch.vue'),

      },
      {
        path: '/branch/update/:id',
        component: () => import('@/views/branch/BranchUpdate.vue'),

      },
      {
        path: '/branch/view/:id',
        component: () => import('@/views/branch/BranchView.vue'),

      },

      //endregion

      {
        path: '/staff',
        component: ()=>import('@/views/staff/StaffList')
      },
      {
        path: '/staff/create',
        component: ()=>import('@/views/staff/StaffCreate')
      },
      {
        path: '/staff/update/:agent_code',
        component: ()=>import('@/views/staff/StaffUpdate')
      },
      {
        path: '/staff/view/:agent_code',
        component: ()=>import('@/views/staff/StaffView')
      },
        //migrate
      {
        path: '/migrate/:agent_code',
        component: ()=>import('@/views/migrate/migrateStaff')
      },

      //commission
      {
        path: '/commission',
        component: ()=>import('@/views/commission/AgentSearch')
      },
      //report
      {
        path: '/report',
        component: ()=>import('@/views/report/reportView')
      }
      ]
  },

  {
    path:'*',
    component:NotFound
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
