import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        lang: 'la'

    },
    getters: {
        isLoggedIn: state => !!state.token,
        getLang: state => state.lang

    },
    mutations: {
        auth_success(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        async logout(state) {
            state.token = ''
            localStorage.removeItem('token')
            await router.push({path: '/login'})
        },
        setLang(state, lang) {
            state.lang = lang
            localStorage.setItem('lang', lang)
        }
    },
    actions: {
        login({commit}, token) {
            commit('auth_success', token)
        },
        logout({commit}) {
            commit('logout')
        },
        setLang({commit}, lang) {
            commit('setLang', lang)
        }
    },
    modules: {}
})
