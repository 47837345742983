<template>
    <v-app>
        <v-navigation-drawer
                v-model="drawer"
                app
                dark
                width="200"
                color="#212121"
        >
            <v-list-item >
                <v-list-item-content>
                    <v-list-item-title  class="text-h4">

                        AMS
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
dark
                    nav
            >
                <v-list-item
                        v-for="item in items"
                        :key="$t(item.title)"
                        :to="item.to"
                        dense

                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                        key="logout"
                        @click="logout"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title> {{$t("main.logout")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app dark color="green darken-4" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{$t('main.company_name')}}</v-toolbar-title>
            <v-spacer></v-spacer>



            <v-btn color="yellow lighten-1" icon>
                <v-icon>mdi-bell-outline</v-icon>
            </v-btn>


            <v-menu v-model="languageMenu" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="text-capitalize" v-bind="attrs" v-on="on" text >
                        <country-flag  :country='activeLang.flag' />
                        <v-icon small right>mdi-menu-down</v-icon>

                    </v-btn>
                </template>
                <v-list >
                    <v-list-item  style="min-height: 20px;min-width: 20px" v-for="(lang, index) in Langs" :key="index" @click="ChangLang(lang)" v-show="lang.value!=activeLang.value">
                        <v-list-item-avatar style="min-height: 20px;min-width: 20px;width: 20px;height: 20px"  class="mr-0"><country-flag  :country='lang.flag' size='small'/></v-list-item-avatar>
                        <v-list-item-content >
                            <v-list-item-title>  {{ lang.title }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>
            </v-menu>

            <v-menu
                    left
                    transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>

                    <v-list-item
                            dense
                            @click="logout"
                    >
                        <v-list-item-title>
                            <v-icon>mdi-logout</v-icon>
                            {{$t("main.logout")}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>


        <v-main>

            <div class="pa-3">
                <router-view></router-view>
            </div>

        </v-main>
    </v-app>
</template>

<script>
    import CountryFlag from 'vue-country-flag'
    export default ({
        name: 'MainLayout',
        components:{
            CountryFlag
        },
        data: () => ({
            drawer: null,
            items: [
                {title: 'main.home', icon: 'mdi-home', to: '/'},
                {title: "main.mn_branch", icon: 'mdi-lan', to: '/branch'},
                {title: "main.mn_staff", icon: 'mdi-account-group-outline', to: '/staff'},
                {title: "main.mn_com", icon: 'mdi-currency-usd', to: '/commission'},
                {title: "main.report", icon: 'mdi-finance', to: '/report'},
                {title: "main.help", icon: 'mdi-help', to: '/help'},
            ],

            languageMenu: false,
        }),
        computed:{
            activeLang(){
                let lang= this.$store.getters.getLang;
                if(lang=='la'){
                    return {value:'la',flag:'la'}
                }else{
                    return {value:'en',flag:'us'}
                }
            },
            Langs(){
                return [
                    { title: 'ພາສາລາວ',value:'la',flag: 'la' },
                    { title: 'English',value:'en',flag:'us' },
                ]
            }

        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')

            },
            ChangLang(lang) {

                // this.activeLang = lang.title
                // this.activeFlag=lang.flag
                this.$i18n.locale=lang.value
                this.$store.dispatch('setLang',lang.value)

            },

        },


    })
</script>

<style scoped>
    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 15px;
    }
    .v-list-item--active {
        background-color: #f0b41d;

    }
    .theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
        opacity: 0.1;

    }
</style>
